//
// footer.scss
//
.footer-subscribe {
    border-bottom: 1px solid rgba(108, 187, 84)
}

.text-header-color{
        color: $primary;
}

.bg-footer {
    background-color: #222A60;
    .footer-link {
        a {
            color: $white;
            line-height: 38px;
            transition: all 0.5s;
            &:hover {
                color: $primary;
            }
        }
    }
    .footer-subcribe {
        input {
            padding: 12px 20px;
            width: 100%;
            font-size: 14px;
            border: none;
            outline: none !important;
            padding-right: 75px;
            padding-left: 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        button {
            position: absolute;
            top: 0px;
            right: 0px;
            outline: none !important;
            border-radius: 0px 5px 5px 0px;
            font-size: 14px;
            padding: 11px 20px;
        }
        form {
            position: relative;
            max-width: 400px;
        }
    }
}

.footer-social {
    a {
        color: $white;
        font-size: 18px;
        transition: all 0.5s;
        display: inline-block;
        &:hover {
            color: $primary;
        }
    }
}

.footer-alt {
    background-color: #1a2254;
}